// Main Body SECTION
const mainBody = {
  firstName: 'Ankit',
  middleName: '',
  lastName: 'Rauniyar',
  message: ' Passionate about changing the world with technology.',
  jobtitle: 'Cloud and Systems Engineer.',
  icons: [
    {
      image: 'linkedin',
      url: 'https://www.linkedin.com/in/rauniyarankit/'
    },
    {
      image: 'medium',
      url: 'https://rauniyarankit.medium.com'
    },
    {
      image: 'github',
      url: 'https://github.com/rauniyarankit'
    },
    {
      image: 'facebook',
      url: 'https://www.facebook.com/ankit.cloud'
    },
    {
      image: 'twitter',
      url: 'https://www.twitter.com/ankit_cloud'
    }
  ]
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/ankit.jpg"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: 'About Me',
  imageLink: require('../assets/img/ankit.webp'),
  imageSize: 375,
  message:
    "Hi, I am a Microsoft Certified Azure Solutions Architect Expert, an AWS Certified Solutions Architect, a Microsoft Certified Azure Administrator and a Cisco Certified Network Associate; with a bachelor's degree in Information Technology. I am an ardent believer of giving back to the mankind, and I have a vision of doing that with exponentially growing technologies in IT. In my leisure, I love experimenting with new techs and messing with my lappy. As introvert as I am, I love expressing myself in Cricket, and I am particularly good at that.",
  resume: require('../assets/pdf/ankit-resume.pdf')
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: false,
  heading: 'Recent Projects',
  gitHubUsername: 'rauniyarankit', //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: []
};

//Certifications Section
const certifications = {
  show: true,
  heading: 'Certifications',
  data: [
    {
      certname: 'Microsoft Certified: Azure Solutions Architect Expert',
      badgeLight: require('../assets/img/mcasae.png'),
      badgeDark: require('../assets/img/mcasae.png'),
      date: 'Issued: June 2024 | Expiry: June 2025',
      link: 'https://learn.microsoft.com/en-us/users/ankitrauniyar/credentials/24d08d4bf9462756'
    },
    {
      certname: 'Microsoft Certified: Azure Administrator Associate',
      badgeLight: require('../assets/img/mcaaa.webp'),
      badgeDark: require('../assets/img/mcaaa.webp'),
      date: 'Issued: January 2022 | Expiry: January 2025',
      link: 'https://learn.microsoft.com/en-us/users/ankitrauniyar/credentials/1feaf364002e3e9'
    },
    {
      certname: 'AWS Certified Solutions Architect – Associate',
      badgeLight: require('../assets/img/aws-csaa.webp'),
      badgeDark: require('../assets/img/aws-csaa.webp'),
      date: 'Issued: October 2021 | Expiry: October 2024',
      link: 'https://www.credly.com/badges/d3924473-fba9-4c68-bc5f-bdc794ba12a1/public_url'
    },
    {
      certname: 'Cisco Certified Network Associate',
      badgeLight: require('../assets/img/ccna-light.webp'),
      badgeDark: require('../assets/img/ccna-dark.webp'),
      date: 'Issued: July 2020 | Expiry: July 2023',
      link: 'https://www.credly.com/badges/d768d4f2-a35d-411e-9f9f-df83cb3733cd/public_url'
    },
    {
      certname: 'Microsoft Certified: Azure Fundamentals',
      badgeLight: require('../assets/img/mcaf.webp'),
      badgeDark: require('../assets/img/mcaf.webp'),
      date: 'Issued: July 2021 | Expiry: N/A',
      link: 'https://www.credly.com/badges/1a885c65-dabc-47e3-9ec9-a001963f1782/public_url'
    },
    {
      certname: 'AWS Certified Cloud Practitioner',
      badgeLight: require('../assets/img/aws-ccp.webp'),
      badgeDark: require('../assets/img/aws-ccp.webp'),
      date: 'Issued: July 2020 | Expiry: October 2024',
      link: 'https://www.credly.com/badges/ec7353c2-c2ec-4657-88ac-c40efc77560f/public_url'
    },
    {
      certname: 'Microsoft Certified: Azure AI Fundamentals',
      badgeLight: require('../assets/img/mcaif.webp'),
      badgeDark: require('../assets/img/mcaif.webp'),
      date: 'Issued: August 2021 | Expiry: N/A',
      link: 'https://www.credly.com/badges/b67431ce-ec35-46a7-927a-5e71c6943ce5/public_url'
    }
  ]
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: 'Proficiency',
  hardSkills: [
    { name: 'AWS Cloud Technologies', value: 90 },
    { name: 'Routing and Switching', value: 85 },
    { name: 'Network Troubleshooting', value: 88 },
    { name: 'Powershell and Shell Scripting', value: 90 },
    { name: 'Azure Cloud Technologies', value: 95 },
    { name: 'Network Design', value: 75 },
    { name: 'Linux', value: 85 },
    { name: 'Network Security', value: 80 }
  ],
  softSkills: [
    { name: 'Goal-Oriented', value: 100 },
    { name: 'Collaboration', value: 80 },
    { name: 'Positivity', value: 90 },
    { name: 'Adaptability', value: 85 },
    { name: 'Problem Solving', value: 85 },
    { name: 'Empathy', value: 85 },
    { name: 'Organization', value: 70 },
    { name: 'Creativity', value: 90 }
  ]
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: 'Get In Touch',
  message:
    'Have any ideas that you want to talk about, or have any questions, or just want to say hi? Please fill the contact form or feel free to email me at',
  email: 'mail@ankitrauniyar.com'
};

export { mainBody, about, repos, skills, getInTouch, certifications };
